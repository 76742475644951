import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Global, ThemeProvider } from '@emotion/react'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import { globalStyles } from '../styles/globalStyles.js'
import theme from '../gatsby-plugin-theme-ui/index'

const Root = styled.div`
  font-family: ${props => props.theme.fonts.body};
`

const Skip = styled.a`
  font-family: ${props => props.theme.fonts.body};
  padding: 0 1rem;
  line-height: 60px;
  background: #2867cf;
  color: white;
  z-index: 101;
  position: fixed;
  top: -100%;
  &:hover {
    text-decoration: underline;
  }
  &:focus,
  &:active,
  &:hover {
    top: 0;
  }
`

const Layout = props => {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
    }
  }
  useEffect(() => window.addEventListener('keydown', handleFirstTab), [])

  return (
    <ThemeProvider theme={theme}>
      <Root className="siteRoot">
        <div className="siteContent">
          <Skip href="#main" id="skip-navigation">
            Skip to content
          </Skip>
          <Menu />
          <main id="main">{props.children}</main>
        </div>
        <Footer />
        <Global styles={globalStyles} />
      </Root>
    </ThemeProvider>
  )
}

export default Layout
